<template>
  <div>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <v-card>
      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                rounded
                label="上級商戶"
                hint="如需修改上級代理請聯繫平台客服"
                persistent-hint
                v-model="localData.agent.name"
                @focus="cantEditHint"
                readonly
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="商戶類型"
                v-model="localData.merchant_type.name"
                @focus="cantEditHint"
                readonly
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                rounded
                label="商戶編號"
                v-model="localData.serial"
                @focus="cantEditHint"
                readonly
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                rounded
                label="商戶名稱"
                v-model="localData.name"
                @focus="cantEditHint"
                readonly
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-combobox
                rounded
                label="登入 IP 白名單"
                v-model="localData.whitelisted_login_ips"
                @focus="cantEditHint"
                hint="預設可留空，請填寫 IP 格式為 127.0.0.1"
                readonly
                persistent-hint
                small-chips
                multiple
                outlined
              ></v-combobox>
            </v-col>

            <v-col cols="12">
              <v-combobox
                rounded
                label="代付域名白名單"
                v-model="localData.whitelisted_pay_domains"
                @focus="cantEditHint"
                readonly
                small-chips
                multiple
                outlined
              ></v-combobox>
            </v-col>

            <v-col cols="12">
              <v-combobox
                rounded
                label="代付 IP 白名單"
                v-model="localData.whitelisted_pay_ips"
                @focus="cantEditHint"
                readonly
                small-chips
                multiple
                outlined
              ></v-combobox>
            </v-col>

            <v-col cols="12">
              <v-combobox
                rounded
                label="代收域名白名單"
                v-model="localData.whitelisted_collect_domains"
                @focus="cantEditHint"
                readonly
                small-chips
                multiple
                outlined
              ></v-combobox>
            </v-col>

            <v-col cols="12">
              <v-combobox
                rounded
                label="代收 IP 白名單"
                v-model="localData.whitelisted_collect_ips"
                @focus="cantEditHint"
                readonly
                small-chips
                multiple
                outlined
              ></v-combobox>
            </v-col>

            <v-col cols="auto">
              <v-switch
                color="primary"
                v-model="localData.enabled_pay_api"
                @click="cantEditHint"
                readonly
                label="代付 API"
                inset
              ></v-switch>
            </v-col>

            <v-col cols="auto">
              <v-switch
                color="primary"
                v-model="localData.enabled_pay_merchant"
                @click="cantEditHint"
                readonly
                label="提現功能"
                inset
              ></v-switch>
            </v-col>

            <v-col cols="auto">
              <v-switch
                color="primary"
                v-model="localData.enabled_collect"
                @click="cantEditHint"
                readonly
                label="儲值功能"
                inset
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="flex-center">
        <!-- <v-btn rounded
          color="primary"
          depressed
          width="320"
          height="40"
          @click="submit"
          :loading="isLoading"
        >
          保存
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { getMerchant, editMerchant } from '@/api/merchantCenter/merchants';

export default {
  //
  data() {
    return {
      localData: {
        agent: { name: '無' },
        merchant_type: { name: '' }
      },

      isLoading: true
    };
  },

  async created() {
    await this.init();
    this.isLoading = false;
  },

  methods: {
    async init() {
      const merchantData = await getMerchant();
      if (!merchantData.agent) merchantData.agent = { name: '無' };
      this.$set(this, 'localData', { ...merchantData });
    },

    cantEditHint() {
      this.$toast.warning('商戶不可編輯資訊');
    },

    async submit() {
      if (!this.$refs.form.validate()) return;

      this.isLoading = true;
      try {
        await editMerchant({ ...this.localData });
        this.$toast('更新成功');
        await this.init();
        this.isLoading = false;
      } catch (error) {
        this.$toast.error('更新失敗');
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
